import React from "react"

/* Styles */
import "./styles.scss"

const ContactForm = () => {
  return (
    <form id="contact-form" method="post" action="/php/sendbymail.php">
      <label>
        NOMBRE
        <input type="text" name="nombre" id="nombre" />
      </label>
      <label>
        CORREO ELECTRONICO*
        <input type="email" name="email" id="email" />
      </label>
      <label>
        TELEFONO
        <input type="tel" name="telefono" id="telefono" />
      </label>
      <label>
        CONSULTA
        <textarea name="consulta" id="consulta" rows="2" />
      </label>
      <button type="submit">Enviar</button>
    </form>
  )
}

export default ContactForm
