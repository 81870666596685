import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import Image from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import ContactForm from "../components/ContactForm"
// import ContactMap from "../../components/ContactMap"

/* Styles */
import "../styles/pages/contacto.scss"

const ContactPage = () => {
  const { bgContact, bgMobileContact, map } = useStaticQuery(
    graphql`
      query {
        bgContact: file(relativePath: { eq: "contact/contacto-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bgMobileContact: file(
          relativePath: { eq: "contact/mobile-bg-contacto.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        map: file(relativePath: { eq: "contact/map.png" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 831) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageBgContact = bgContact.childImageSharp.fluid
  const imgBgMobileContact = bgMobileContact.childImageSharp.fluid
  const imgMap = map.childImageSharp.fluid

  // Background-Image Breakpoints
  const sources = [
    imgBgMobileContact,
    {
      ...imageBgContact,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout bgImage={sources}>
      <SEO
        title="Contact"
        keywords={[
          `Agencia`,
          `Transformacion Digital`,
          `The Hybrid`,
          `Growth`,
          `Rosario`,
          `Innovación`,
        ]}
      />
      <section id="contact" className="pt-5">
        <Container className="container-contact px-5 px-lg-2">
          <Row>
            <Col className="mx-auto" xs={12} lg={8} xl={7}>
              <h1 className="title gradient-title text-white">Contacto</h1>
              <div className="map-wrap">
                <div className="map">
                  <Image className="img-fluid" fluid={imgMap} alt="bg-shape" />
                </div>
                <p className="direction text-white">
                  {`Madres de Plaza 25 de Mayo 3020 - Nordlink 
                  Rosario, Argentina. | hello@thehybrid.digital`}
                </p>
              </div>
            </Col>
            <Col className="mx-auto py-4" xs={12} lg={8} xl={5}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default ContactPage
